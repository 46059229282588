import {createRouter, createWebHistory} from "vue-router";
import Body from "../components/body";

import book from "@/pages/book.vue";
import Login from "@/pages/auth/login.vue";
import {authMiddleware, loginMiddleware} from "@/core/middleware/authMid";
import ConfigurationLayout from "@/pages/configuration/modules/ConfigurationLayout";
import SortingLayout from "@/pages/configuration/module_sorting/SortingLayout.vue";
import SortModuleGroups from "@/pages/configuration/module_sorting/includes/SortModuleGroups.vue";
import SortModules from "@/pages/configuration/module_sorting/includes/SortModules.vue";
import SortSubModules from "@/pages/configuration/module_sorting/includes/SortSubModules.vue";
import DefaultRolePermission from "@/pages/configuration/default_role_permission/DefaultRolePermission.vue";
import AgencyManagement from "@/pages/agencies/management/AgencyManagement.vue";
import AgencyDiagram from "@/pages/agencies/diagram/AgencyDiagram.vue";
import TranslationList from "@/pages/translation/list/TranslationList.vue";
import TranslationModuleDetail from "@/pages/translation/module_detail/TranslationModuleDetail.vue";
import {ToastMessage} from "@/core/services/general.service";


const preventNotYetRoute = (to, from, next) => {
    next(from);
}

const routes = [
    {
        path: "/Login",
        name: "login",
        component: Login,
        beforeEnter: loginMiddleware,
    },
    {
        path: "/",
        component: Body,
        beforeEnter: authMiddleware,
        children: [
            {
                path: "",
                name: "defaultRoot",
                component: () => import("@/pages/dashboard/AgencyDashboard.vue"),
            },
            {
                path: "books", // '/books' değil, 'books' olmalı
                name: "book",
                component: book,
            },
            {
                path: "SystemConfiguration",
                children: [
                    {
                        path: "ModuleAndRoles",
                        name: "ModuleAndRoles",
                        component: ConfigurationLayout,
                    },
                    {
                        path: "DefaultRolePermission",
                        name: "DefaultRolePermission",
                        component: DefaultRolePermission,
                    },
                    {
                        path: "ModuleSorting",
                        component: SortingLayout,
                        children: [
                            {
                                path: "ModuleGroups",
                                name: "SortModuleGroups",
                                component: SortModuleGroups,
                            },
                            {
                                path: "Modules",
                                name: "SortModules",
                                component: SortModules,
                            },
                            {
                                path: "SubModules",
                                name: "SortSubModules",
                                component: SortSubModules,
                            },
                        ],
                    },
                    {
                        path: "Currencies",
                        name: 'currencies',
                        component: () => import("@/pages/configuration/currency/Index.vue"),
                    },
                ],
            },
            {
                path: "Agencies",
                children: [
                    {
                        path: "Management",
                        component: AgencyManagement,
                    },
                    {
                        path: "Diagram",
                        component: AgencyDiagram,
                    },
                    {
                        path: "DefaultMarkups",
                        component: () => import("@/pages/agencies/default_markup/Index.vue"),
                    },
                ],
            },
            {
                path: "Users",
                children: [
                    {
                        path: "Management",
                        component: () => import("@/pages/users/management/UserManagement.vue"),
                    },
                    {
                        path: "LogManagement",
                        component: () => import("@/pages/users/logs/UserLogManagement.vue"),
                    }
                ],
            },
            {
                path: "Translation",
                children: [
                    {
                        path: "TranslationList",
                        component: TranslationList,
                    },
                    {
                        path: ":module_name/:selected_language/Show",
                        name: "translation_module_detail",
                        component: TranslationModuleDetail,
                    },
                ],
            },
            {
                path: "Packages",
                name: "packages",
                children: [
                    {
                        path: "Hotel",
                        name: "hotel",
                        children: [
                            {
                                path: "Search/:location?/:locationType?/:locationCode?/:locationPreviewText?/:nationality?/:firstDate?/:lastDate?/:customers?/:custom?",
                                name: "search",
                                component: () => import("@/pages/packages/hotel/search/SearchHotel.vue"),
                            },
                            {
                                path: "Reservation/:token",
                                name: "reservation",
                                component: () => import("@/pages/packages/hotel/reservation/HotelReservation.vue"),
                            },
                            {
                                path: "Payment/:token",
                                name: "payment",
                                component: () => import("@/pages/packages/hotel/reservation/payment/HotelPayment.vue"),
                            },
                            {
                                path: "Successful/:token",
                                name: "successful",
                                component: () => import("@/pages/packages/hotel/reservation/successful/ReservationSuccessful.vue"),
                            },
                            {
                                path: 'Services',
                                name: 'services',
                                component: () => import("@/pages/packages/hotel/services/Index.vue")
                            }
                        ],
                    },
                ],
            },
            {
                path: "not.yet",
                name: "not.yet",
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Global beforeEach guard
router.beforeEach((to, from, next) => {


    if (to.name === 'not.yet') {
        ToastMessage('warning', 'This page is still under development and will be available soon.', 'Page Under Construction!');
        next(false);
        return;
    }


    if (to.name !== 'login') {
        authMiddleware(to, from, next);
    } else {
        next();
    }
});


export default router;
